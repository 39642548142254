/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {AsideMenu} from './AsideMenu'
import {Dropdown2} from '../../../partials'
import '../../../../App.css'
const AsideDefault: FC = () => {
  const {classes} = useLayout()
  const [isMinified, setIsMinified] = useState(true)
  // var mini = true
  // const toggleSidebar = () => {
  //   if (mini) {
  //     console.log('opening sidebar')
  //     document?.getElementById('mySidebar').style.width = '250px'
  //     document.getElementById('main').style.marginLeft = '250px'
  //     mini = false
  //   } else {
  //     document.getElementById('mySidebar').style.width = '100px'
  //     document.getElementById('main').style.marginLeft = '100px'
  //     mini = true
  //   }
  // }

  const toggleSidebar = () => {
    setIsMinified(!isMinified)
  }

  // const toggleSidebar = () => {
  //   const sidebar = document?.getElementById('mySidebar')
  //   const main = document?.getElementById('main')

  //   if (mini) {
  //     console.log('opening sidebar')
  //     if (sidebar) sidebar.style.width = '250px'
  //     if (main) main.style.marginLeft = '250px'
  //     mini = false
  //   } else {
  //     console.log('closing sidebar')
  //     if (sidebar) sidebar.style.width = '100px'
  //     if (main) main.style.marginLeft = '100px'
  //     mini = true
  //   }
  // }

  // console.log(“opening sidebar”);
  // document.getElementById(“mySidebar”).style.width = “250px”;
  // document.getElementById(“main”).style.marginLeft = “250px”;

  // console.log(“closing sidebar”);
  // document.getElementById(“mySidebar”).style.width = “100px”;
  // document.getElementById(“main”).style.marginLeft = “100px”;
  return (
    <div
      // onMouseDown={toggleSidebar}
      id='kt_aside'
      className={clsx(
        isMinified
          ? 'aside overflow-visible ePulse-roundedCorner sidebar'
          : 'aside overflow-visible ePulse-roundedCorner sidebar expanded',
        classes.aside.join(' ')
      )}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      //data-kt-drawer-width='auto'
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_toggle'
    >
      {/* begin::Logo */}
      <div
        className='aside-logo d-none d-lg-flex flex-column align-items-center py-4 mb-18'
        id='kt_aside_logo'
      >
        <Link to='/dashboard'>
          <img
            src={toAbsoluteUrl('/media/ePulse/logoInverse.svg')}
            alt='logo'
            className='h-45px '
          />
        </Link>
      </div>
      {/* end::Logo */}
      <img
        src={toAbsoluteUrl('/media/ePulse/toggle_sidebar.svg')}
        alt='toggle sidebar'
        className='toggle-icon'
        onMouseDown={toggleSidebar}
      />
      {/* begin::Nav */}
      <div
        className='asaside-nav d-flex flex-column align-lg-center flex-column-fluid w-100 pt-5 pt-lg-0'
        id='kt_aside_nav'
      >
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Nav */}

      {/* begin::Footer */}
      {/* <div
        className='aside-footer d-flex flex-column align-items-center flex-column-auto'
        id='kt_aside_footer'
      > */}
      {/* begin::Menu */}
      {/* <div className='mb-7'> */}
      {/* <button
            type='button'
            className='btn btm-sm btn-icon btn-color-white btn-active-color-primary btn-active-light'
            data-kt-menu-trigger='click'
            data-kt-menu-overflow='true'
            data-kt-menu-placement='top-start'
            data-bs-toggle='tooltip'
            data-bs-placement='right'
            data-bs-dismiss='click'
            title='Quick actions'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen008.svg'
              className='svg-icon-2 svg-icon-lg-1'
            />
          </button> */}
      {/* <Dropdown2 /> */}
      {/* </div> */}
      {/* end::Menu */}
      {/* </div> */}
      {/* end::Footer */}
    </div>
  )
}

export {AsideDefault}
