/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {actionsUsers} from '../../../../setup/redux/rootReducer'
import {useMutation} from '@apollo/client'
import {LOGOUT} from '../../../../app/GraphQLQueries/Auth'
import useMutationApi from '../../../../hooks/useMutation'
const HeaderUserMenu: FC = () => {
  const dispatch = useDispatch()

  const {createData, loading: loading2, error, data} = useMutationApi(LOGOUT)

  // const [logoutSession, {data, error}] = useMutation(LOGOUT, {
  //   onError: (error: any) => {
  //     // if (error?.networkError?.statusCode === 440) {
  //       dispatch(actionsUsers.logout())
  //     // }
  //   },

  //   onCompleted: (data: any) => {
  //     dispatch(actionsUsers.logout())
  //   },
  // })

  const logout = async () => {
    // await logoutSession({
    //   variables: {
    //     logoutType: 'one',
    //   },
    // })
    const response = await createData({logoutType: 'one'})

    dispatch(actionsUsers.logout())
  }

  useEffect(() => {
    // if (error) {
    //   console.log('ERRORADDADMIN.......', error)
    // }
  }, [])
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      {/* <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_name} {currentUser?.first_name}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div> */}

      {/* <div className='separator my-2'></div> */}

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5 text-black fw-normal'>
          My Patients
        </Link>
      </div>
      <div className='separator my-2 mx-8 mx-8'></div> */}

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5 text-black fw-normal'>
          Schedule Appointment
        </Link>
      </div>
      <div className='separator my-2 mx-8'></div> */}

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5 text-black fw-normal'>
          My Profile
        </Link>
      </div>
      <div className='separator my-2 mx-8'></div> */}

      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5 text-black fw-normal'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div>

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5 text-black fw-normal'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5 text-black fw-normal'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5 text-black fw-normal'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5 text-black fw-normal'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2 mx-8'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5 text-black fw-normal'>
          My Statements
        </a>
      </div>

      <div className='separator my-2 mx-8'></div>

      <Languages />
    */}

      {/* <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5 text-black fw-normal'>
          Account Settings
        </Link>
      </div> */}
      {/* <div className='separator my-2 mx-8'></div> */}

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5 text-black fw-normal'>
          Refer Doctor
        </Link>
      </div> */}
      {/* <div className='separator my-2 mx-8'></div> */}
      <div className='menu-item px-5'>
        <Link to={'/updatePassword'} className='menu-link px-5 text-black fw-normal'>
          Update Password
        </Link>
      </div>
      <div className='separator my-2 mx-8'></div>

      <div className='menu-item px-5'>
        <Link to={'/session-history'} className='menu-link px-5 text-black fw-normal'>
          Login History
        </Link>
      </div>
      <div className='separator my-2 mx-8'></div>
      <div className='menu-item px-5'>
        <a onClick={() => logout()} className='menu-link px-5 text-black fw-normal'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
