import { gql } from '@apollo/client'

export const SENDMOTP_MUTATION = gql`
  mutation GetAdminResetPasswordOtp($email: String!) {
    getAdminResetPasswordOtp(email: $email)
  }
`
export const LOGIN_MUTATION = gql`
  mutation adminUserLogin(
    $email: String!
    $password: String!
    $browserUniqueId: String!
    $ipAddress: String
    $userAgent: String
    $browserName: String
  ) {
    adminUserLogin(
      adminLoginInput: {
        email: $email
        password: $password
        deviceDetail: {
          browserUniqueId: $browserUniqueId
          userAgent: $userAgent
          ipAddress: $ipAddress
          browserName: $browserName
        }
      }
    ) {
      email
      countryCode
      mobileNumber
      otp
      otpExpirationTime
    }
  }
`

export const OTPVERIFY_MUTATION = gql`
  mutation verifyAdminOtp(
    $email: String!
    $countryCode: Int!
    $mobileNumber: MobileNumber!
    $otp: Int!
    $browserName: String
    $browserUniqueId: String!
    $ipAddress: String
    $userAgent: String
  ) {
    verifyAdminOtp(
      adminOtpInput: {
        email: $email
        countryCode: $countryCode
        mobileNumber: $mobileNumber
        otp: $otp
        deviceDetail: {
          browserName: $browserName
          browserUniqueId: $browserUniqueId
          ipAddress: $ipAddress
          userAgent: $userAgent
        }
      }
    ) {
      userId
      token
      tokenExpiration
      userProfile {
        firstName
        lastName
        gender
        dob
        profilePhoto
        preferredLanguage
      }
      userPermissions
    }
  }
`

export const VERIFY_OTP_FORGOT_PASSWORD_MUTATION = gql`
  mutation VerifyAdminResetPasswordOtp($email: String!, $otp: Int!, $newPassword: String!) {
    verifyAdminResetPasswordOtp(
      adminResetPwdInput: {email: $email, otp: $otp, newPassword: $newPassword}
    )
  }
`
export const UPDATE_ADMIN_PASSWORD = gql`
  mutation updateAdminPassword(
    $oldPassword: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    updateAdminPassword(
      userInput: {
        oldPassword: $oldPassword
        newPassword: $newPassword
        confirmPassword: $confirmPassword
      }
    )
  }
`

export const LOGOUT = gql`
  mutation logoutSession($logoutType: logoutType!) {
    logoutSession(logoutType: $logoutType)
  }
`

export const GET_LOGIN_HISTORY_LIST = gql`
  query getUserFailedLoginHistory($page: Int!, $limit: Int!) {
    getUserFailedLoginHistory(listFilter: {page: $page, limit: $limit}) {
      data {
        loginAttemptDate
        ipAddress
        userAgent
        reason
        createdDate
        updatedDate
      }
      total
      pageSize
      page
      totalPages
      __typename
    }
  }
`

export const GET_SESSION_LIST = gql`
query getUserSessionList($page: Int!, $limit: Int!, $sessionType: sessionType!) {
  getUserSessionList(
    listFilter: {page: $page, limit: $limit}
    sessionType: $sessionType
  ) {
    data {
      isActive
      deviceType
      createdDate
    }
    total
    pageSize
    page
    totalPages
    __typename
  }
}`