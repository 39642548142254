import { useEffect } from 'react'
import { KTSVG } from '../../../_ePulse/helpers'
import '../../../App.css'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { MenuComponent } from '../../../_ePulse/assets/ts/components'
import { checkPermission } from '../../App'

export const UserListTable = (props: any) => {
  const navigate = useNavigate()
  console.log(props)
  const initial = (firstName: string, lastName: string) => {
    const name: any = `${firstName} ${lastName}`
    let initialName = name
      .match(/(^\S\S?|\b\S)?/g)
      .join('')
      .match(/(^\S|\S$)?/g)
      .join('')
      .toUpperCase()
    return initialName
  }

  useEffect(() => {
    // initial()
  }, [])

  useEffect(() => {
    MenuComponent.reinitialization() // import from _metronic/assets/ts/components
  }, [])

  return (
    <>
      <div className='dashboard-table-row'>
        <div className='d-flex  align-items-center col-4'>
          {/* <div className='me-5 '>
            {props?.photo ? (
              <img
                src={''}
                alt=''
                className='rounded-circle symbol symbol-20px'
                width={'45px'}
                height={'45px'}
              />
            ) : (
              <div className='rounded-circle d-flex fs-4 ePulse-textColor-primary text-center w-45px h-45px ePulse-bg-light align-items-center justify-content-center'>
                <span> {initial(props.user.firstName, props.user.lastName)}</span>
              </div>
            )}
          </div> */}
          <div className='d-flex flex-column me-2 col-4 '>
            <div className='d-flex flex-stack '>
              <span className='col-value'>
                {props.user.firstName} {props.user.lastName}
              </span>
            </div>
            <div className='d-flex flex-stack'>
              <span className='col-label'>{props.user.email}</span>
            </div>
          </div>
        </div>

        <div className='d-flex flex-column me-2 col-3 justify-content-center'>
          <div className='d-flex flex-stack'>
            <span className='col-value'>{props.user.mobileNumber || 'NA'}</span>
          </div>
        </div>
        <div className='d-flex flex-column me-2 col-3  justify-content-center'>
          <div className='d-flex flex-stack'>
            <span className='col-value'>
              {props?.user?.email ? props?.user?.email : 'NA'}
            </span>
          </div>
        </div>

        {/* <div className='d-flex flex-column me-2 col-3 align-items-end justify-content-center '>
          <div className='d-flex w-120px justify-content-center'>
            <div
              // href=''
              className='btn btn-light btn-active-light-primary btn-sm'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              <KTSVG path='/media/ePulse/action_button.svg' className='svg-icon-2 m-0' />
            </div>

            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
              data-kt-menu='true'
            >
              <Link
                to='/users/view'
                state={{userId: props?.user?.id}}
                className=' d-flex align-items-center justify-content-center py-1'
              >
                <div className='d-flex flex-row align-items-center justify-content-center'>
                  <KTSVG path='/media/ePulse/view.svg' className='svg-icon-4 m-0' />
                  <span className='menu-link px-3 text-dark'>View</span>
                </div>
              </Link>
              <Link
                to='/users/edit'
                state={{userId: props?.user?.id}}
                className=' d-flex align-items-center justify-content-center py-1'
              >
                <div className='d-flex flex-row align-items-center justify-content-center'>
                  <KTSVG path='/media/ePulse/edit.svg' className='svg-icon-4 m-0' />
                  <span className='menu-link px-3 text-dark'>Edit</span>
                </div>
              </Link>
            </div>
          </div>
        </div> */}
        <div className='d-flex flex-row me-2 col-2 justify-content-start '>
          {checkPermission('getMyUserProfile') &&
            <div className='d-flex flex-row align-items-center justify-content-center'>
              <button
                onClick={() => navigate('/users/view', { state: { userId: props?.user?.id } })}
                className='menu-link px-3 text-dark border-0 bg-white fw-semibold'
              >
                <KTSVG path='/media/ePulse/view_icon.svg' className='m-0' />
              </button>
            </div>
          }
          {checkPermission('adminEditUserProfile') &&
            <div className='d-flex flex-row align-items-center justify-content-center'>
              <button
                onClick={() => navigate('/users/edit', { state: { userId: props?.user?.id } })}
                className='menu-link px-3 text-dark border-0 bg-white fw-semibold'
              >
                <KTSVG path='/media/ePulse/edit_icon.svg' className='m-0' />
              </button>
            </div>
          }
        </div>
      </div>
    </>
  )
}


