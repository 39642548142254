import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG, toAbsoluteUrl } from '../../../_ePulse/helpers'
import { Toolbar } from '../../../_ePulse/layout/components/toolbar/Toolbar'

import SVG from 'react-inlinesvg'
import { useNavigate } from 'react-router-dom'
import '../../../App.css'
import { MenuComponent } from '../../../_ePulse/assets/ts/components'
import moment from 'moment'
export const ClinicListTable = (props: any) => {
  const navigate = useNavigate()

  useEffect(() => {
    MenuComponent.reinitialization() // import from _metronic/assets/ts/components
  }, [])

  return (
    <>
      <div className='dashboard-table-row '>
        <div className='d-flex  align-items-center col-2'>
          <div className='d-flex flex-column me-2 '>
            <div className='d-flex flex-stack '>
              <span className='col-value'>{props.clinic.businessName}</span>
            </div>
          </div>
        </div>

        <div className='d-flex flex-column me-2 col-2 justify-content-center'>
          <div className='d-flex flex-stack'>
            <span className='col-value'>
              {props.clinic.firstName} {props.clinic.lastName}
            </span>
          </div>
        </div>
        <div className='d-flex flex-column me-2 col-2  justify-content-center'>
          <div className='d-flex flex-stack'>
            <span className='col-value'>{props.clinic.businessType || 'NA'}</span>
          </div>
        </div>
        <div className='d-flex flex-column me-2 col-2 justify-content-center'>
          <div className='d-flex flex-stack'>
            <span className='col-value'>
              {props.clinic.businessPhone}
            </span>
          </div>
        </div>
        <div className='d-flex flex-column me-2 col-2 justify-content-center'>
          <div className='d-flex flex-stack'>
            <span className='col-value'>
              {moment(props.clinic.createdDate).format("YYYY-MM-DD")}
            </span>
          </div>
        </div>


        <div className='d-flex flex-row me-2 col-2 justify-content-center '>
          <div className='d-flex flex-row align-items-center justify-content-center'>
            <button
              onClick={() =>
                navigate('/business/view', { state: { clinicId: props?.clinic?.businessId } })
              }
              className='menu-link px-3 text-dark border-0 bg-white fw-semibold'
            >
              <KTSVG path='/media/ePulse/view_icon.svg' className='m-0' />
            </button>
          </div>
          <div className='d-flex flex-row align-items-center justify-content-center'>
            <button
              onClick={() =>
                navigate('/business/edit', { state: { clinicId: props?.clinic?.businessId } })
              }
              className='menu-link px-3 text-dark border-0 bg-white fw-semibold'
            >
              <KTSVG path='/media/ePulse/edit_icon.svg' className='m-0' />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
