import {gql} from '@apollo/client'

// export const DASHBOARD_COUNT = gql`
//   query GetDashboardCount($startDate: DateTime!, $endDate: DateTime!) {
//     getDashboardCount(startDate: $startDate, endDate: $endDate) {
//       clinicCount
//       patientCount
//       pharmacyCount
//       labCount
//       otherCount
//     }
//   }
// `

export const DASHBOARD_COUNT = gql`
  query GetDashboardCount {
    getDashboardCount {
      clinicCount
      patientCount
      pharmacyCount
      labCount
      otherCount
      __typename
    }
  }
`
