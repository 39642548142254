/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Modal, Row} from 'react-bootstrap'

import '../../App.css'
import {toAbsoluteUrl} from '../../_ePulse/helpers'

export function AddStaffModal(props: any) {
  const [gender, setGender] = useState(10)
  const [role, setrole] = useState(10)
  const [speciality, setspeciality] = useState(10)
  const [stateCouncil, setstateCouncil] = useState(10)

  const handleChangeGender = (event: any) => {
    setGender(event.target.value)
  }

  const handleChangeStateCouncil = (event: any) => {
    setstateCouncil(event.target.value)
  }

  const handleChangeSpeciality = (event: any) => {
    setspeciality(event.target.value)
  }

  const handleChangeRole = (event: any) => {
    setrole(event.target.value)
  }

  return (
    <>
      <div>
        <Modal
          show={props.modalState}
          dialogClassName='modal-90w'
          onHide={() => props.setModalState(false)}
          // backdrop='static'
        >
          {/* <Modal.Header closeButton></Modal.Header> */}
          <Modal.Body>
            <div className='d-flex flex-row'>
              <div className='me-4'>
                <div className='position-relative'>
                  <img src={toAbsoluteUrl('/media/ePulse/userIcon.svg')} width={100} height={100} />
                  <img
                    src={toAbsoluteUrl('/media/ePulse/camera.svg')}
                    className='position-absolute bottom-0 end-0'
                  />
                </div>
              </div>
              <div className='position-sticky '>
                <div className=' border-bottom border-grey mb-4'>
                  <span className='text-black fs-1 fw-bold '>Add Staff Member</span>
                </div>
                <TextField
                  className='my-4'
                  id='outlined-basic'
                  label='Staff First Name.*'
                  placeholder='Staff First Name.*'
                  variant='outlined'
                  type={'number'}
                  size='small'
                  fullWidth
                  inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                />

                <TextField
                  className='mt-4'
                  id='outlined-basic'
                  label='Staff Last Name*'
                  placeholder='Staff Last Name*'
                  variant='outlined'
                  type='text'
                  size='small'
                  fullWidth
                />

                <TextField
                  className='mt-4'
                  id='outlined-basic'
                  label='Staff Mobile*'
                  placeholder='Staff Mobile*'
                  variant='outlined'
                  type='text'
                  size='small'
                  fullWidth
                />

                <TextField
                  className='mt-4'
                  id='outlined-basic'
                  label='Staff Email ID*'
                  placeholder='Staff Email ID*'
                  variant='outlined'
                  type='text'
                  size='small'
                  fullWidth
                />

                <Box className='my-4'>
                  <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>Gender</InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      size='small'
                      value={gender}
                      label='Gender'
                      onChange={handleChangeGender}
                    >
                      <MenuItem value={10}>Male</MenuItem>
                      <MenuItem value={20}>Female</MenuItem>
                      <MenuItem value={30}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box className='my-4'>
                  <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>Staff Role</InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      size='small'
                      value={role}
                      label='Staff Role'
                      onChange={handleChangeRole}
                    >
                      <MenuItem value={10}>Doctor</MenuItem>
                      <MenuItem value={20}>Nurse</MenuItem>
                      <MenuItem value={30}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {role === 10 ? (
                  <>
                    <TextField
                      className='mt-4'
                      id='outlined-basic'
                      label='MCI Number'
                      placeholder='MCI Number'
                      variant='outlined'
                      type='text'
                      size='small'
                      fullWidth
                    />

                    <Box className='my-4'>
                      <FormControl fullWidth>
                        <InputLabel id='demo-simple-select-label'>State Council</InputLabel>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          size='small'
                          value={stateCouncil}
                          label='State Council'
                          onChange={handleChangeStateCouncil}
                        >
                          <MenuItem value={10}>Male</MenuItem>
                          <MenuItem value={20}>Female</MenuItem>
                          <MenuItem value={30}>Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    <Box className='my-4'>
                      <FormControl fullWidth>
                        <InputLabel id='demo-simple-select-label'>Speciality</InputLabel>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          size='small'
                          value={speciality}
                          label='Speciality'
                          onChange={handleChangeSpeciality}
                        >
                          <MenuItem value={10}>Doctor</MenuItem>
                          <MenuItem value={20}>Nurse</MenuItem>
                          <MenuItem value={30}>Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className='d-flex flex-row justify-content-center mt-6 '>
              <button className='border-0 ePulse-appColor-primary px-6 py-3 fs-6 rounded-pill text-white '>
                <span className='fw-semibold'>Confirm & Add Patient</span>
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}
