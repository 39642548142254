/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {TextField} from '@mui/material'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Modal, Row} from 'react-bootstrap'

import '../../App.css'
import {toAbsoluteUrl} from '../../_ePulse/helpers'

export function AddPatientModal(props: any) {
  const [gender, setGender] = useState<string>('Male')
  const [age, setAge] = useState<number>()

  const getAge = (date: any) => {
    var years = moment().diff(date, 'years')
    console.log('AGE', years)
    setAge(years)
  }

  return (
    <>
      <div>
        <Modal
          show={props.modalState}
          //   aria-labelledby='contained-modal-title-vcenter'
          // size='sm'
          //   aria-labelledby='example-custom-modal-styling-title'
          //   dialogClassName='modal-90w'
          onHide={() => props.setModalState(false)}
          backdrop='static'
        >
          {/* <Modal.Header closeButton></Modal.Header> */}
          <Modal.Body className='px-4'>
            <div className='d-flex flex-row'>
              <div className='me-4'>
                <div className='position-relative'>
                  <img src={toAbsoluteUrl('/media/ePulse/userIcon.svg')} width={100} height={100} />
                  <img
                    src={toAbsoluteUrl('/media/ePulse/camera.svg')}
                    className='position-absolute bottom-0 end-0'
                  />
                </div>
              </div>
              <div className='position-sticky '>
                <div className=' border-bottom border-grey mb-4'>
                  <span className='text-black fs-1 fw-bold '>Add New Patient</span>
                </div>
                <TextField
                  className='my-4'
                  id='outlined-basic'
                  label='Patient Phone No.*'
                  placeholder='Patient Phone No.*'
                  variant='outlined'
                  type={'number'}
                  size='small'
                  fullWidth
                  inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                />

                <TextField
                  className='mt-4'
                  id='outlined-basic'
                  label='Patient Name*'
                  placeholder='Patient Name*'
                  variant='outlined'
                  type='text'
                  size='small'
                  fullWidth
                />
                <div className='my-3'>
                  <span className='text-black fs-3 fw-bold '>Gender</span>
                </div>
                <div className='d-flex flex-row rounded border border-primary border-1'>
                  <div
                    className={
                      gender === 'Male'
                        ? 'col rounded-start ePulse-appColor-primary text-center border-end border-primary py-2'
                        : 'col text-center border-end border-primary py-2'
                    }
                    onClick={() => setGender('Male')}
                  >
                    <span
                      className={
                        gender === 'Male'
                          ? 'text-white fs-5 fw-bold'
                          : 'ePulse-textColor-primary fs-5 fw-bold'
                      }
                    >
                      Male
                    </span>
                  </div>
                  <div
                    className={
                      gender === 'Female'
                        ? 'col ePulse-appColor-primary  text-center border-end border-primary py-2'
                        : 'col text-center  border-end border-primary py-2'
                    }
                    onClick={() => setGender('Female')}
                  >
                    <span
                      className={
                        gender === 'Female'
                          ? 'text-white fs-5 fw-bold'
                          : 'ePulse-textColor-primary fs-5 fw-bold'
                      }
                    >
                      Female
                    </span>
                  </div>
                  <div
                    className={
                      gender === 'Other'
                        ? 'col rounded-end ePulse-appColor-primary text-center border-primary py-2'
                        : 'col text-center  border-primary py-2'
                    }
                    onClick={() => setGender('Other')}
                  >
                    <span
                      className={
                        gender === 'Other'
                          ? 'text-white fs-5 fw-bold'
                          : 'ePulse-textColor-primary fs-5 fw-bold'
                      }
                    >
                      Other
                    </span>
                  </div>
                </div>
                <div className='mt-8 mb-6'>
                  <span className='text-black fs-3 fw-bold'>Confirm Age</span>
                </div>
                <div className='d-flex flex-row  align-items-center mt-4 '>
                  <TextField
                    className=' w-50'
                    id='outlined-basic'
                    label='Enter DOB*'
                    variant='outlined'
                    type='date'
                    size='small'
                    InputLabelProps={{shrink: true}}
                    onChange={(e: any) => getAge(e.target.value)}
                    InputProps={{inputProps: {max: moment(new Date()).format('YYYY-MM-DD')}}}
                  />
                  <div className='ms-6 '>
                    <span className='fs-6 ePulse-textColor-primary'>Age: {age} Years</span>
                  </div>
                </div>
                {/* <div className='my-4'>
                  <span className='text-black fs-3 fw-bold'>Preferred Language</span>
                </div> */}

                {/* <select
                  className='form-select form-select-solid w-50'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  defaultValue={'1'}
                >
                  <option></option>
                  <option value='1'>English</option>
                  <option value='2'>Hindi</option>
                </select> */}

                <div className='d-flex w-50 align-items-center border-0  py-4 rounded'>
                  <input
                    type='radio'
                    value='REAL'
                    // checked={appointmentType === 'CONSULTATION'}
                    // onChange={(e: any) => setappointmentType(e.target.value)}
                  />
                  <div className='symbol symbol-30px'>
                    <span className='mx-4 text-dark fs-6'>
                      Notify patient through SMS and Whatsapp
                    </span>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
            <div className='d-flex flex-row justify-content-center mt-6 '>
              <button className='border-0 ePulse-appColor-primary px-6 py-3 fs-6 rounded-pill text-white '>
                <span className='fw-semibold'>Confirm & Add Patient</span>
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}
