import { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../_ePulse/helpers'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_ePulse/layout/core'
import '../../../App.css'
import { addDays, subDays } from 'date-fns'
import { Statics } from './statics'
import { Users } from './Users'
import { Clinics } from './Clinics'
import { ApprovalRequest } from './ApprovalRequest'
import { checkPermission } from '../../App'
import { useDispatch, useSelector } from 'react-redux'
import { actionsUsers } from '../../../setup/redux/rootReducer'
import { ToastMessageContainer } from '../../../_ePulse/layout/components/ToastMessageContainer'

import moment from 'moment'

const DashboardPage = () => {
  const dispatch = useDispatch()
  const [roleType, setRoleType] = useState('Doctor')
  const updateDates = (dates: any) => {
    setState(dates)
    dispatch(actionsUsers.updateStartDate(moment(dates[0].startDate).startOf('day').utc()))
    dispatch(actionsUsers.updateEndDate(moment(dates[0].endDate).endOf('day').utc()))
  }
  const [dashboardCount, setDashboardCount] = useState<any>({})

  const [state, setState] = useState([
    {
      endDate: new Date(),
      startDate: subDays(new Date(), 30),
      // startDate: new Date(),
      // endDate: addDays(new Date(), 30),
      key: 'selection',
    },
  ])



  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])



  return (
    <>
      <ToastMessageContainer />
      <Statics dateState={(dates: any) => updateDates(dates)} />
      <div className='d-flex flex-row mt-4 dashboard-landing'>
        <div className='d-flex flex-column w-75 dashboard-left'>
          <div className='d-flex flex-row w-100 dashboard-counters'>
            <div
              className='col count-block'
              onClick={() => setRoleType('Doctor')}
              style={{ cursor: 'pointer' }}
            >
              <div className='count-block-content'>
                <div className='content-name'>Doctors</div>
                <div className='content-count'>{dashboardCount?.clinicCount}</div>
              </div>
              <img
                src={toAbsoluteUrl('/media/ePulse/icons/dashboard/doctor.svg')}
                className='h-45px'
                alt=''
              />
            </div>
            <div
              className='col count-block'
              onClick={() => setRoleType('Consumer')}
              style={{ cursor: 'pointer' }}
            >
              <div className='count-block-content'>
                <div className='content-name'>Consumers</div>
                <div className='content-count'>{dashboardCount?.patientCount}</div>
              </div>
              <img
                src={toAbsoluteUrl('/media/ePulse/icons/dashboard/consumers.svg')}
                className='h-45px'
                alt=''
              />
            </div>
            <div
              className='col count-block'
              onClick={() => setRoleType('Pharmacy')}
              style={{ cursor: 'pointer' }}
            >
              <div className='count-block-content'>
                <div className='content-name'>Pharmacy</div>
                <div className='content-count'>{dashboardCount?.pharmacyCount}</div>
              </div>
              <img
                src={toAbsoluteUrl('/media/ePulse/icons/dashboard/pharmacies.svg')}
                className='h-45px'
                alt=''
              />
            </div>
            <div
              className='col count-block'
              onClick={() => setRoleType('ScanCenter')}
              style={{ cursor: 'pointer' }}
            >
              <div className='count-block-content'>
                <div className='content-name'>Scan and Diagnostic</div>
                <div className='content-count'>{dashboardCount?.labCount}</div>
              </div>
              <img
                src={toAbsoluteUrl('/media/ePulse/icons/dashboard/diagnostic.svg')}
                className='h-45px'
                alt=''
              />
            </div>
          </div>

          {roleType === 'Consumer' ? (
            <>{checkPermission('getDashboardUserList') && <Users />}</>
          ) : (
            <>
              {
                checkPermission('getDashboardBusinessList') && (
                  <Clinics
                    roleType={roleType}
                    dashboardCount={(count: any) => setDashboardCount(count)}
                  />
                )
              }
            </>
          )}
        </div>
        <div className='d-flex flex-column col ms-5 dashboard-right'>
          {checkPermission('getApprovalPendingBusinessList') &&
            <ApprovalRequest dates={state} />
          }

          {/* <RecentActivities /> */}
        </div>
      </div>
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
